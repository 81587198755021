<template>
  <div class="tw-pt-7.5">
    <main-page-banners class="tw-mb-9" />

    <main-page-ranking class="tw-mb-10" />

    <main-page-news class="tw-mb-10" />

    <main-page-articles class="tw-mb-10" />

    <subscribe-section />
  </div>
</template>

<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router';
import SubscribeSection from '~/shared/components/subscribe/SubscribeSection.vue';
import MainPageBanners from '~/features/main-page/components/banners/MainPageBanners.vue';
import MainPageRanking from '~/features/main-page/components/ranking/MainPageRanking.vue';
import { useAppStore } from '~/store/app';
import { AuthTab } from '~/data/domain/auth.entity';
import MainPageNews from '~/features/news/components/MainPageNews.vue';
import MainPageArticles from '~/features/article/components/MainPageArticles.vue';

const appStore = useAppStore();
const loginAction = useRouteQuery<'login' | null>('action', null);
if (loginAction.value === 'login') {
  appStore.toggleAuthDialog(AuthTab.login, true);
}
</script>
