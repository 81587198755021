<template>
  <div v-if="partData.length" class="tw-grid lg:tw-grid-cols-2 tw-gap-x-7.5 main-page-dapps-table">
    <de-table
      v-for="i in partData.length"
      :key="i"
      :table="i === 1 ? table1 : table2"
      :loading="isLoading"
      class="dapps-table-part"
      @sort="onSort"
    >
      <template #body_title="{ data }: { data: FormattedDappRankingModelShortWithPosition }">
        <div class="tw-flex tw-h-[28px]">
          <nuxt-link-locale
            :to="`/dapp/${data.slug}`"
            :title="data.title"
            class="tw-inline-flex heading-h5"
          >
            <div class="tw-flex tw-items-center">
              <img
                v-if="data.icon"
                loading="lazy"
                :src="data.icon"
                decoding="async"
                fetchpriority="low"
                :alt="`${data.title} logo`"
                class="tw-w-700 tw-h-700 tw-mr-2.5 tw-rounded-full"
              />
              <span
                class="heading-h5 tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-max-w-[250px]"
              >
                {{ data.title }}
              </span>
            </div>
          </nuxt-link-locale>
        </div>
      </template>
    </de-table>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { createColumnHelper, getCoreRowModel, useVueTable } from '@tanstack/vue-table';
import { useNuxtApp } from '#app';
import { splitAt } from 'remeda';
import { computed, onServerPrefetch, ref, watch } from 'vue';
import { getLocalizedTimeLabel, TimeVariations } from '~/data/model/time-variations.model';
import { useUserStore } from '~/store/user';
import { DappMetric } from '~/data/domain/metrics/dapp-metrics';
import DeTable from '~/shared/components/table/DeTable.vue';
import { sortArray } from '~/utils/sort-array';
import type { SortType, TableColumnSort } from '~/shared/components/table/table.type';
import { useAppStore } from '~/store/app';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import {
  formatDappRankingModelShortMetrics,
  type FormattedDappRankingModelShortWithPosition,
} from '~/features/dapps/models/dapp-ranking.model';
import { DappService } from '~/features/dapps/services/dapp.service';
import { dappKeys } from '~/features/dapps/queries/dapp.query';
import { THIRTY_MINUTES_IN_MILLISECONDS } from '~/shared/constants/time-duration.constant';
import type { AppLocale } from '~/data/domain/app.locale';

const { $customFetch } = useNuxtApp();
const { t } = useI18n();
const appStore = useAppStore();
const userStore = useUserStore();

const dappRepo = DappService($customFetch);

const {
  data: dapps,
  isLoading,
  suspense,
} = useQuery({
  queryKey: computed(() => dappKeys.topVolumeList(userStore.user.currencyShortName)),
  queryFn: (queryKey) => dappRepo.getDappsTopVolume(appStore.locale, queryKey),
  staleTime: THIRTY_MINUTES_IN_MILLISECONDS,
});

onServerPrefetch(async () => {
  await suspense();
});

const sortBy = ref<keyof FormattedDappRankingModelShortWithPosition>('position');
const isDesc = ref(false);
const sortingFn = ref<SortType>('alphanumeric');

const sortedDappsList = computed(() => {
  const sorted = Array.isArray(dapps.value) ? [...dapps.value] : [];

  return sortArray(sorted, sortBy.value, isDesc.value, sortingFn.value);
});

const partData = computed(() => {
  const middleIndex = (sortedDappsList.value?.length ?? 0) / 2;

  return splitAt(sortedDappsList.value || [], Math.ceil(middleIndex));
});

const timeLabel = getLocalizedTimeLabel(TimeVariations.Hours24, t);
const columnHelper = createColumnHelper<FormattedDappRankingModelShortWithPosition>();
const columns = [
  columnHelper.accessor('position', {
    cell: (info) => info.getValue(),
    header: '#',
    enableHiding: false,
    size: 40,
    minSize: 40,
    maxSize: 40,
    sortingFn: 'alphanumeric',
    meta: {
      thClass: '!tw-pl-0 tw-text-center',
      tdClass: '!tw-pl-0',
    },
  }),
  columnHelper.accessor('title', {
    id: 'title',
    cell: (info) => info.getValue(),
    header: t('coins.dapp'),
    minSize: 250,
    sortingFn: 'text',
    meta: {
      thClass: '!tw-pl-0',
      tdClass: '!tw-pl-0',
    },
  }),
  columnHelper.accessor('balance24hFormatted', {
    id: DappMetric.Balance24h,
    cell: (info) => info.getValue(),
    header: t('coins.metrics.metricForXTime', { metric: 'coins.metrics.balance', time: timeLabel }),
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('volume24hFormatted', {
    id: DappMetric.Volume24h,
    cell: (info) => info.getValue(),
    header: t('coins.metrics.metricForXTime', { metric: 'coins.metrics.volume', time: timeLabel }),
    sortingFn: 'alphanumeric',
  }),
];
const pagination = ref({
  pageIndex: 0,
  pageSize: 5,
});

const table1 = useVueTable<FormattedDappRankingModelShortWithPosition>({
  get data() {
    return partData.value[0];
  },
  get columns() {
    return columns;
  },
  manualSorting: true,
  getCoreRowModel: getCoreRowModel(),
  state: {
    get pagination() {
      return pagination.value;
    },
    get sorting() {
      return [
        {
          id: sortBy.value,
          desc: isDesc.value,
        },
      ];
    },
  },
});
const table2 = useVueTable<FormattedDappRankingModelShortWithPosition>({
  get data() {
    return partData.value[1];
  },
  get columns() {
    return columns;
  },
  manualSorting: true,
  getCoreRowModel: getCoreRowModel(),
  state: {
    get pagination() {
      return pagination.value;
    },
    get sorting() {
      return [
        {
          id: sortBy.value,
          desc: isDesc.value,
        },
      ];
    },
  },
});

function onSort(ev: TableColumnSort<FormattedDappRankingModelShortWithPosition>) {
  if (!dapps.value?.length || !ev.sortingFn) return;

  sortBy.value = ev.id;
  isDesc.value = !ev.desc;
  sortingFn.value = ev.sortingFn;
}

const queryClient = useQueryClient();

function updateCache(locale: AppLocale) {
  queryClient.setQueryData(
    dappKeys.topVolumeList(userStore.user.currencyShortName),
    (oldData: FormattedDappRankingModelShortWithPosition[]) => {
      if (oldData) {
        return oldData.map((item) => {
          return {
            ...item,
            ...formatDappRankingModelShortMetrics(item, locale, userStore.user.currencyShortName),
          };
        });
      }

      return oldData;
    },
  );
}

watch(
  () => appStore.locale,
  (val: AppLocale) => {
    updateCache(val);
  },
);
</script>

<style lang="postcss" scoped>
.main-page-dapps-table :deep(.dapps-table-part:nth-child(2) .de-datatable-thead) {
  @apply max-lg:tw-hidden;
}
</style>
