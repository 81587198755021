import { isNullish } from 'remeda';
import type { SortType } from '~/shared/components/table/table.type';

export function sortArray<T>(
  arr: T[],
  key: keyof T,
  isDesc: boolean = false,
  sortType: SortType,
): T[] {
  return arr.sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    // Handle nullish values explicitly
    if (isNullish(valueA) && isNullish(valueB)) return 0; // Both are null/undefined
    if (isNullish(valueA)) return isDesc ? 1 : -1; // valueA is null/undefined
    if (isNullish(valueB)) return isDesc ? -1 : 1; // valueB is null/undefined

    let comparison = 0;
    if (sortType === 'alphanumeric') {
      comparison = compareAlphanumeric(valueA, valueB);
    } else if (sortType === 'text') {
      comparison = compareText(String(valueA), String(valueB));
    }

    return isDesc ? -comparison : comparison;
  });
}

// Alphanumeric comparison for id
function compareAlphanumeric(a: any, b: any): number {
  if (a === b) {
    const aStr = String(a);
    const bStr = String(b);

    return aStr.localeCompare(bStr); // if numbers are the same, compare as strings
  }

  return a - b;
}

// Text comparison for title
function compareText(a: string, b: string): number {
  return a.localeCompare(b, undefined, { sensitivity: 'base' });
}
