import type { BackendBannerModel } from '~/data/services/types/banner.type';
import type { ContentAuthor } from '~/shared/types/content.type';

export interface BannerModel {
  id: number;
  title: string;
  slug: string;
  image: string | null;
  author: ContentAuthor;
}

export function createBannerModel(raw: BackendBannerModel): BannerModel {
  return {
    id: raw.id,
    title: raw.title,
    slug: raw.slug,
    image: raw.image,
    author: {
      image: raw.author_image,
      name: raw.author_name,
    },
  };
}
