<template>
  <section class="tw-container">
    <de-horizontal-carousel :items="banners || []">
      <template v-if="isLoading">
        <de-skeleton
          v-for="i in 4"
          :key="i"
          size-classes="tw-w-full tw-h-[270px]"
          class="tw-flex-shrink-0 tw-mr-2.5 lg:tw-w-[calc((100%-40px)/4)]"
        />
      </template>

      <template v-else-if="banners?.length">
        <main-page-banner
          v-for="item in banners"
          :key="item.id"
          class="tw-w-full md:tw-w-auto tw-h-[270px] tw-flex-shrink-0 tw-mr-2.5"
          :class="{
            'lg:tw-w-[calc((100%-20px)/2)]': banners.length === 2,
            'lg:tw-w-[calc((100%-30px)/3)]': banners.length === 3,
            'lg:tw-w-[calc((100%-40px)/4)]': banners.length === 4,
          }"
          :banner="item"
        />
      </template>
    </de-horizontal-carousel>
  </section>
</template>

<script setup lang="ts">
import { useNuxtApp } from '#app';
import MainPageBanner from '~/features/main-page/components/banners/MainPageBanner.vue';
import DeHorizontalCarousel from '~/shared/components/carousel/DeHorizontalCarousel.vue';
import { useAppStore } from '~/store/app';
import DeSkeleton from '~/shared/components/lib/DeSkeleton.vue';
import { MainPageService } from '~/features/main-page/services/main-page.service';
import { useQuery } from '@tanstack/vue-query';
import { computed, onServerPrefetch } from 'vue';
import { mainPageKeys } from '~/features/main-page/queries/main-page.query';
import { TWENTY_FOUR_HOURS_IN_MILLISECONDS } from '~/shared/constants/time-duration.constant';

const appStore = useAppStore();
const nuxtApp = useNuxtApp();
const mainPageRepo = MainPageService(nuxtApp.$customFetch);

const {
  data: banners,
  suspense,
  isLoading,
} = useQuery({
  queryKey: computed(() => mainPageKeys.banners(appStore.locale)),
  queryFn: () => mainPageRepo.getBanners(),
  staleTime: TWENTY_FOUR_HOURS_IN_MILLISECONDS,
});

onServerPrefetch(async () => {
  await suspense();
});
</script>
