<template>
  <section class="tw-container">
    <h1 class="heading-h1 tw-mb-2.5">
      {{ t('news.title') }}
    </h1>
    <p class="tw-text-primary-300 tw-text-350 tw-mb-7.5">
      {{ t('news.subtitle') }}
      <!--      <nuxt-link-locale to="/news" class="tw-underline tw-font-semibold">-->
      <!--        {{ t('common.buttons.readMore') }}-->
      <!--      </nuxt-link-locale>-->
    </p>

    <div class="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-7.5">
      <template v-if="isLoading">
        <content-card-skeleton v-for="i in 6" :key="i" />
      </template>

      <template v-else>
        <content-card
          v-for="(item, index) in news"
          :key="index"
          :href="`/news/${item.slug}`"
          :author="item.author"
          :title="item.title"
          :content="item.shortDescription"
          :created-at="item.publishedAtFormatted"
          content-class="max-md:tw-hidden"
        />
      </template>
    </div>

    <div class="tw-text-center tw-mt-7.5">
      <de-view-more-button href="/news" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useNuxtApp } from '#app';
import ContentCard from '~/shared/components/content/ContentCard.vue';
import DeViewMoreButton from '~/shared/components/DeViewMoreButton.vue';
import { useAppStore } from '~/store/app';
import { useQuery } from '@tanstack/vue-query';
import { computed, onServerPrefetch } from 'vue';
import { newsKeys } from '~/features/news/queries/news.query';
import { NewsService } from '~/features/news/services/news.service';
import ContentCardSkeleton from '~/shared/components/content/ContentCardSkeleton.vue';
import { FIFTEEN_MINUTES_IN_MILLISECONDS } from '~/shared/constants/time-duration.constant';

const { t } = useI18n();
const appStore = useAppStore();
const { $customFetch } = useNuxtApp();
const newsRepo = NewsService($customFetch);

const {
  data: news,
  isLoading,
  suspense,
} = useQuery({
  queryKey: computed(() => newsKeys.mainPage(appStore.locale)),
  queryFn: (queryKey) => newsRepo.getNewsMainPage(queryKey),
  staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
});

onServerPrefetch(async () => {
  await suspense();
});
</script>
