import type { CustomFetch } from '~/shared/types/custom-fetch.type';
import type { BannerModel } from '~/data/model/banner.model';
import { createBannerModel } from '~/data/model/banner.model';
import type { BackendBannerModel } from '~/data/services/types/banner.type';

export const MainPageService = (fetch: CustomFetch) => ({
  async getBanners(): Promise<BannerModel[]> {
    const banners: BackendBannerModel[] = await fetch('api/main-page/slider-news', {
      credentials: 'include',
    });

    return banners.map(createBannerModel);
  },
});
