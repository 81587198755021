<template>
  <section class="tw-container">
    <div class="max-md:tw-hidden">
      <de-tabs
        v-model:value="selectedTab"
        :model="rankingCategories"
        :variant="TabVariantOptions.pills"
        menu-class="!tw-gap-2.5"
        class="tw-mb-5"
      />

      <keep-alive>
        <component :is="tableComponent" />
      </keep-alive>

      <div class="tw-text-center tw-mt-7.5">
        <de-view-more-button :label="t('common.buttons.viewAll')" :href="rankingUrl" />
      </div>
    </div>

    <div class="md:tw-hidden">
      <h1 class="heading-h2 tw-mb-4">{{ t('mainPage.ranks') }}</h1>
      <div class="tw-flex tw-flex-wrap tw-gap-2.5">
        <nuxt-link-locale
          v-for="item in rankingCategories"
          :key="item.id"
          :to="item.id === RankingCategory.Dapps ? '/ranking' : `/ranking/${item.id}`"
          class="heading-5.1 tw-bg-primary-600 tw-p-5 tw-w-[calc(50%-5px)] md:tw-w-[calc(25%-30px/4)] tw-text-center"
        >
          {{ item.label }}
        </nuxt-link-locale>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import DeTabs from '~/shared/components/lib/DeTabs.vue';
import { TabVariantOptions } from '~/shared/components/lib/tabs/tab.entity';
import { RANKING_CATEGORIES, RankingCategory } from '~/data/domain/ranking';
import MainPageDappsTable from '~/features/main-page/components/ranking/MainPageDappsTable.vue';
import DeViewMoreButton from '~/shared/components/DeViewMoreButton.vue';

// const MainPageCryptoTable = defineAsyncComponent(
//   () => import('~/features/main-page/components/ranking/MainPageCryptoTable.vue'),
// );
// const MainPageDexTable = defineAsyncComponent(
//   () => import('~/features/main-page/components/ranking/MainPageDexTable.vue'),
// );
// const MainPageCexTable = defineAsyncComponent(
//   () => import('~/features/main-page/components/ranking/MainPageCexTable.vue'),
// );

const { t } = useI18n();
const selectedTab = ref(RankingCategory.Dapps);
const rankingUrl = computed(() =>
  selectedTab.value === RankingCategory.Dapps ? '/ranking' : `/ranking/${selectedTab.value}`,
);

const rankingCategories = RANKING_CATEGORIES.map((item) => ({
  ...item,
  label: t(item.labelKey, 2),
}));

const tableComponent = computed(() => {
  switch (selectedTab.value) {
    case RankingCategory.Dapps:
      return MainPageDappsTable;
    // case RankingCategory.Cryptocurrency:
    //   return MainPageCryptoTable;
    // case RankingCategory.Dex:
    //   return MainPageDexTable;
    // case RankingCategory.Cex:
    //   return MainPageCexTable;
    default:
      return MainPageDappsTable;
  }
});
</script>
