<template>
  <div class="tw-relative">
    <vue-horizontal ref="horizontal" :button="false" responsive @scroll-debounce="onScrollDebounce">
      <slot />
    </vue-horizontal>

    <div
      v-if="items.length > 1"
      class="tw-absolute tw-top-full tw-left-0 tw-right-0 tw-flex tw-justify-center tw-mt-2.5 md:tw-hidden"
    >
      <div
        v-for="(item, i) in items"
        :key="item.id"
        class="tw-p-1 tw-cursor-pointer"
        @click="onIndexClick(i)"
      >
        <div
          class="tw-rounded-full tw-w-150 tw-h-150"
          :class="i === index ? 'tw-bg-primary-200' : 'tw-bg-primary-500'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VueHorizontal from 'vue-horizontal';
import { ref } from 'vue';
import type { BannerModel } from '~/data/model/banner.model';

defineProps<{
  items: BannerModel[];
}>();

const horizontal = ref(null);

const hasPrev = ref(false);
const hasNext = ref(false);
const scrollWidth = ref(0);
const left = ref(0);
const progress = ref(0);
const index = ref(0);

function onScrollDebounce({
  hasNext: newHasNext,
  hasPrev: newHasPrev,
  scrollWidth: newScrollWidth,
  width,
  left: newLeft,
}: {
  hasNext: boolean;
  hasPrev: boolean;
  scrollWidth: number;
  width: number;
  left: number;
}) {
  hasPrev.value = newHasPrev;
  hasNext.value = newHasNext;
  scrollWidth.value = newScrollWidth;
  left.value = newLeft;
  progress.value = newLeft / newScrollWidth;
  index.value = Math.round(newLeft / width);
}

function onIndexClick(i: number) {
  horizontal.value?.scrollToIndex(i);
}
</script>
