<template>
  <section class="tw-container">
    <h1 class="heading-h1 tw-mb-2.5">
      {{ t('articles.title', 2) }}
    </h1>
    <p class="tw-text-primary-300 tw-text-350 tw-mb-7.5">
      {{ t('articles.subtitle') }}
      <!--      <nuxt-link-locale to="/analytics" class="tw-underline tw-font-semibold">-->
      <!--        {{ t('common.buttons.readMore') }}-->
      <!--      </nuxt-link-locale>-->
    </p>

    <de-tabs
      v-model:value="selectedCategoryId"
      :model="categories"
      :variant="TabVariantOptions.pills"
      menu-class="!tw-gap-x-2.5 tw-mb-7.5"
    />

    <div class="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-7.5">
      <template v-if="isLoading">
        <content-card-skeleton v-for="i in 6" :key="i" />
      </template>

      <template v-else-if="articles">
        <content-card
          v-for="(item, index) in articles"
          :key="index"
          :image="item.image"
          :href="`/analytics/${item.slug}`"
          :author="item.author"
          :title="item.title"
          :content="item.shortDescription"
          :created-at="item.publishedAtFormatted"
          content-class="max-md:tw-hidden"
          title-class="max-md:tw-mb-0"
        />
      </template>
    </div>

    <div class="tw-text-center tw-mt-7.5">
      <de-view-more-button href="/analytics" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useNuxtApp } from '#app';
import { computed, onServerPrefetch, ref } from 'vue';
import DeViewMoreButton from '~/shared/components/DeViewMoreButton.vue';
import ContentCard from '~/shared/components/content/ContentCard.vue';
import { TabVariantOptions } from '~/shared/components/lib/tabs/tab.entity';
import DeTabs from '~/shared/components/lib/DeTabs.vue';
import { useAppStore } from '~/store/app';
import { useQuery } from '@tanstack/vue-query';
import { ArticleService } from '~/features/article/services/article.service';
import type { ArticleModelBase } from '~/features/article/models/article.model';
import { articleKeys } from '~/features/article/queries/article.query';
import ContentCardSkeleton from '~/shared/components/content/ContentCardSkeleton.vue';
import { FIFTEEN_MINUTES_IN_MILLISECONDS } from '~/shared/constants/time-duration.constant';

const { t } = useI18n();
const appStore = useAppStore();
const nuxtApp = useNuxtApp();
const articleRepo = ArticleService(nuxtApp.$customFetch);

const {
  data: articlesData,
  isLoading,
  suspense,
} = useQuery({
  queryKey: computed(() => articleKeys.mainPage(appStore.locale)),
  queryFn: (queryKey) => articleRepo.getArticlesMainPage(queryKey),
  staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
});

onServerPrefetch(async () => {
  await suspense();
});

const selectedCategoryId = ref<string>('recentlyAdded');
const articles = computed<ArticleModelBase[] | null>(() => {
  if (!articlesData.value) {
    return null;
  }

  if (selectedCategoryId.value === 'recentlyAdded') {
    return articlesData.value?.latestArticles;
  }

  return articlesData.value?.categoryArticles[selectedCategoryId.value] || null;
});

const recentlyAddedCategory = {
  id: 'recentlyAdded',
  label: t('articles.categories.recentlyAdded'),
};

const categories = computed(() => {
  const initialCategories = [recentlyAddedCategory];
  const beCategories = articlesData.value?.categories;

  if (beCategories) {
    initialCategories.push(...beCategories);
  }

  return initialCategories;
});
</script>
